import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from "aws-amplify";

const stack = require('./aws-exports.js').default;

const callbackUrl = window.location.origin;
const config = {
    oauth: {
        domain: stack.oauth.domain,
        scope: ['openid'],
        redirectSignIn: callbackUrl,
        redirectSignOut: callbackUrl,
        responseType: 'code'
    },
    // Cognito
    aws_cognito_identity_pool_id: stack.aws_cognito_identity_pool_id,
    aws_cognito_region: stack.aws_cognito_region,
    aws_user_pools_id: stack.aws_user_pools_id,
    aws_user_pools_web_client_id: stack.aws_user_pools_web_client_id,
    aws_user_files_s3_bucket: stack.aws_user_files_s3_bucket,
    aws_user_files_s3_bucket_region: stack.aws_user_files_s3_bucket_region,

    aws_project_region: stack.aws_project_region,
}

console.log('Amplify config', config)
Amplify.configure(config)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
